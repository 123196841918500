body {
  background-color: #ffffff;
}

/*.modal-box {*/
/*  max-height: calc(100vh - 3rem);*/
/*  padding-bottom: env(safe-area-inset-bottom);*/
/*}*/

.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: 'Georgia', serif;
  font-size: 14px;
  padding: 15px;
}

.RichEditor-editor {
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
  margin: 0 -15px -15px;
  padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
}

.RichEditor-styleButton {
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
}

.RichEditor-activeButton {
  color: #5890ff;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  border-radius: 5px;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  overflow: hidden;
  @apply shadow-xl;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
  padding: 0 !important;
}

/*.divider:before, .divider:after{*/
/*  @apply h-px bg-white;*/
/*  --tw-bg-opacity: var(--tw-border-opacity, 0.1);*/
/*}*/

.card-divider {
  @apply divider;
  @apply my-0;
}

/*.card-divider:before, .card-divider:after{*/
/*  @apply h-px bg-brandDark opacity-10;*/
/*  --tw-bg-opacity: var(--tw-border-opacity, 0.1);*/
/*}*/

.shadow-brand {
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.05);
}

.shadow-brand-talks {
  box-shadow: 0px 6px 0px rgba(0, 0, 0, 0.05);
}

.shadow-brand-button {
  box-shadow: 0px 10px 0px -4px rgba(0, 0, 0, 0.05);
}

.shadow-brand-top {
  box-shadow: 0px -10px 0px -4px rgba(0, 0, 0, 0.07);
}

.talks-background {
  background: linear-gradient(167.22deg, #EFFBFF 0%, #B8EAFF 100%);
}

.results-bad-background {
  background: linear-gradient(167.22deg, #FFEFEF 0%, #FFB8B8 100%);
}

.results-good-try-background {
  background: linear-gradient(167.22deg, #FFF9EF 0%, #FFDBB8 100%);
}

.results-good-background {
  background: linear-gradient(167.22deg, #E6FCEF 0%, #AEF9C3 100%);
}

#container {
  display: flex;
  align-items: center;
  justify-content: center;
}

#container::after {
  display: flex;
  background: transparent;
  border-radius: 300px;
  border-color: white;
  border-width: 1px;
  height: 100px;
  width: 100px;
  opacity: 0.0;
  content: "";
  z-index: 10;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.circle {
  background: transparent;
  border-radius: 300px;
  border-color: white;
  border-width: 1px;
  width: 100px;
  height: 100px;
  position: absolute;
  opacity: 0;
  z-index: 0;
  /*animation: pulsate 3s infinite ease-out;*/
  /*animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);*/
  animation: pulsate 3s infinite ease-out;
  animation-iteration-count: infinite;
}

.circle2 {
  background: transparent;
  border-radius: 300px;
  border-color: white;
  border-width: 1px;
  width: 100px;
  height: 100px;
  position: absolute;
  opacity: 0;
  z-index: 10;
  /*animation: pulsate 3s infinite ease-out;*/
  /*animation: scaleIn 4s infinite cubic-bezier(.36, .11, .89, .32);*/
  animation: pulsate2 3s infinite ease-out;
  animation-iteration-count: infinite;
}

.circle3 {
  background: transparent;
  border-radius: 300px;
  border-color: white;
  border-width: 1px;
  width: 100px;
  height: 100px;
  position: absolute;
  opacity: 0;
  z-index: 10;
  /*animation: pulsate 3s infinite ease-out;*/
  /*animation-iteration-count: infinite;*/
  animation: pulsate3 3s infinite ease-out;
  animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1.0, 1.0);
    opacity: 0.0;
  }
  15% {
    opacity: 1.0;
  }
  50% {
    transform: scale(2.2, 2.2);
    opacity: 0.0;
  }
  100% {
    transform: scale(3.0, 3.0);
    opacity: 0.0;
  }
}

@keyframes pulsate2 {
  0% {
    transform: scale(1.0, 1.0);
    opacity: 0.0;
  }
  15% {
    opacity: 1.0;
  }
  50% {
    transform: scale(1.9, 1.9);
    opacity: 0.0;
  }
  100% {
    transform: scale(3.0, 3.0);
    opacity: 0.0;
  }
}

@keyframes pulsate3 {
  0% {
    transform: scale(1.0, 1.0);
    opacity: 0.0;
  }
  15% {
    opacity: 1.0;
  }
  50% {
    transform: scale(1.7, 1.7);
    opacity: 0.0;
  }
  100% {
    transform: scale(3.0, 3.0);
    opacity: 0.0;
  }
}

/* BILLING */

.StripeElement {
  @apply border-2;
  display: block;
  margin: 10px 0 0px 0;
  max-width: 500px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Montserrat", sans-serif;
  /*box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,*/
  /*rgba(0, 0, 0, 0.0196078) 0px 1px 0px;*/
  /*border: 2px;*/
  outline: 0;
  /*border-radius: 4px;*/
  background: white;
}

.tab.tab-active {
  @apply border-primary text-primary;
}

.tab:hover {
  @apply text-primary;
}

.input-group > :first-child {
  @apply border rounded-none;
}

.input-group > :last-child {
  @apply rounded-r-full;
}

.mirrorX {
  transform: rotateY(180deg);
}

.list-style-checkbox {
  list-style-image: url('assets/icons/check-circle.svg');
}

.list-style-close {
  list-style-image: url('assets/icons/x-circle.svg');
}

@keyframes bounce {
  0%, 100% {
    transform: translateX(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
/*.input-group {*/
/*  */
/*}*/
