.artboard.phone-1 {
    width: 230px;
    height: 500px;
}

.mockup-phone {
    display: inline-block;
    border: 4px solid #444;
    border-radius: 30px;
    background-color: #000;
    padding: 10px;
    margin: 0 auto;
    overflow: hidden;
}

.mockup-phone .display {
    overflow: hidden;
    border-radius: 20px;
    margin-top: -25px;
}

.mockup-phone .camera {
    position: relative;
    top: 0px;
    left: 0px;
    background: #000;
    height: 20px;
    width: 120px;
    margin: 0 auto;
    border-bottom-left-radius: 17px;
    border-bottom-right-radius: 17px;
    z-index: 11;
}

.mockup-phone .camera {
    position: relative;
    top: -5px;
    left: 0px;
    background: #000;
    height: 20px;
    width: 120px;
    margin: 0 auto;
    border-bottom-left-radius: 13px;
    border-bottom-right-radius: 13px;
    z-index: 11;
}

