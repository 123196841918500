/*@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");*/
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* IF oklch not support, for example Safari < 17.0  */
@supports not (color: oklch(0 0 0)) {
    :root {
        color-scheme: light;
        --fallback-p: #20262E;
        --fallback-pc: #d4dbff;
        --fallback-s: #f5e1ce;
        --fallback-sc: #fff9fc;
        --fallback-a: #4DF393;
        --fallback-ac: #00100d;
        --fallback-n: #3e5570;
        --fallback-nc: #d7dde4;
        --fallback-b1: #ffffff;
        --fallback-b2: #e5e6e6;
        --fallback-b3: #e5e6e6;
        --fallback-bc: #1f2937;
        --fallback-in: #ADE5DC;
        --fallback-inc: #000000;
        --fallback-su: #00cca7;
        --fallback-suc: #000000;
        --fallback-wa: #fc8800;
        --fallback-wac: #000000;
        --fallback-er: #f83a3a;
        --fallback-erc: #000000;
    }
    @media (prefers-color-scheme: dark) {
        :root {
            color-scheme: dark;
            --fallback-p: #20262E;
            --fallback-pc: #d4dbff;
            --fallback-s: #f5e1ce;
            --fallback-sc: #fff9fc;
            --fallback-a: #4DF393;
            --fallback-ac: #00100d;
            --fallback-n: #3e5570;
            --fallback-nc: #d7dde4;
            --fallback-b1: #ffffff;
            --fallback-b2: #e5e6e6;
            --fallback-b3: #e5e6e6;
            --fallback-bc: #1f2937;
            --fallback-in: #ADE5DC;
            --fallback-inc: #000000;
            --fallback-su: #00cca7;
            --fallback-suc: #000000;
            --fallback-wa: #fc8800;
            --fallback-wac: #000000;
            --fallback-er: #f83a3a;
            --fallback-erc: #000000;
        }
    }
}

@layer base {
    @font-face {
        font-family: "Poppins", sans-serif;
    }

    /*@font-face {*/
    /*    font-family: "Montserrat", sans-serif;*/
    /*    src: url("assets/fonts/Monserrat.woff2");*/
    /*}*/
}

.menu :where(li.bordered>*) {
    border-color: #4C637D;
}

.menu :where(li.bordered>*):active {
    background-color: #4C637D;
}

.flip-card {
    perspective: 1000px;
}

.flip-card-inner {
    transform-style: preserve-3d;
}

.flip-card-front,
.flip-card-back {
    top: 0;
    left: 0;
    transform-origin: 50% 50%;
    backface-visibility: hidden;
}

.flip-card-back {
    transform: rotateX(180deg);
}